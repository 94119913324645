import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import squad from "../../Assets/Projects/Squad.png";
import Upmis from "../../Assets/Projects/upmis.webp";
import academy from "../../Assets/Projects/academy.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={squad}
              isBlog={false}
              title="Squad AI"
              description="Developed a task management software for teams thatintegrates AI capabilities for task assignment andmonitoring. The platform serves as an alternative to Slack,allowing users to efficiently allocate tasks, track progress,and assign different team members to tasks for streamlinedteam collaboration."
              
              demoLink="https://app.trysquad.today/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Upmis}
              isBlog={false}
              title="UPMIS"
              description="Created a software solution for the local government inBangladesh, facilitating efficient tax collection andmanagement within unions. UPMIS streamlines the processof issuing trade licenses and various certificates, while alsostoring and organizing all relevant area statistics forcomprehensive record-keeping and data analysis."
              
              demoLink="https://demo.upmis.online/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={academy}
              isBlog={false}
              title="Academy Pro"
              description="Designed an advanced learning management system (LMS)similar to Udemy, offering a comprehensive platform foronline courses. AcademyPro provides users with the abilityto take exams and track their growth and progress. Thisadditional feature enhances the learning experience byenabling users to assess their performance and tailor theirlearning journey for continuous improvement"
              demoLink="https://academy-pro-website.onrender.com/"              
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Plant AI"
              description="Used the plant disease dataset from Kaggle and trained a image classifer model using 'PyTorch' framework using CNN and Transfer Learning with 38 classes of various plant leaves. The model was successfully able to detect diseased and healthy leaves of 14 unique plants. I was able to achieve an accuracy of 98% by using Resnet34 pretrained model."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col> */}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai For Social Good"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col> */}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
